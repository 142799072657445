:root {
  --copart-blue: #1d5ab9;
}

#root {
  height: 100%;
}

.LogoContainer {
  background-color: var(--copart-blue);
  padding: 10px;
  height: 60px;
}

.FormContainer {
  padding: 18px;
  height: calc(100vh - 60px);
  overflow-y: auto;
  background-color: rgb(241,241,241);
}

.label-text,
.field > label {
  color: var(--copart-blue) !important;
}

.label-text.required:after {
  content: '*';
  margin-left: 2px;
  color: red;
}

.ui.input:disabled,.ui.disabled.input, .ui.form .disabled.field  {
  opacity: 0.8 !important;
}

.ui.input:not(.disabled) input[disabled] {
  opacity: 0.8;
}

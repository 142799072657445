.BenefitsList {
  margin-top: 20px !important;
}

.benefitsListItem {
  margin: 10px 0 10px 0;
}

.banner {
  height: 32vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ccc;
  margin: -18px -18px 0 -18px;
}

.banner > i {
  font-size: 120px !important;
}

.error-header,
.summary-header {
  font-weight: bold;
  font-size: 40px;
  margin: 25px 0 25px 0;
}

.error-subtext,
.summary-subtext {
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 25px;
}

.error-reason,
.summary-instructions {
  font-size: 18px;
}

.error-content,
.summary-content {
  padding: 30px;
}

.returnToHomeBtn {
  width: 35% !important;
  margin-left: 30px !important;
}

.titleHeader {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 14px;
}

.contactFields {
  width: 49%;
  display: inline-block;
}

.zipField,
.stateField,
.radiusField,
.trucksField {
  width: 23.5%;
  display: inline-block;
  margin-right: 2% !important;
}

.driversField {
  width: 23.5%;
  display: inline-block;
}

.towImage {
  background: url('./images/tow-provider.png');
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: calc(100% + 36px);
  height: auto;
  min-height: 35%;
  box-sizing: border-box;
  margin: -18px 0 20px -18px;
  background-size: cover;
  background-position: center center;
}

.imageContainer {
  margin-left: 10%;
}

.towImageHeader {
  font-size: 36px;
  color: white;
  font-weight: 700;
}

.towImageSubtext {
  font-size: 22px;
  color: white;
  font-weight: 500;
}

.version-update-popup {
  text-align: center;
  width: 200px;
  left: calc(50vw - 100px) !important;
  animation: fadeOut ease 4s;
  animation-delay: 2s;
}

@media only screen and (max-width: 600px) {
  .contactFields {
    width: 100%;
    display: block;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .zipField,
  .stateField,
  .radiusField,
  .trucksField,
  .driversField {
    width: 49%;
    display: inline-block;
    margin-right: 1% !important;
  }

  .radiusField,
  .driversField {
    margin-left: 1% !important;
    margin-right: 0 !important;
  }

  .towImage {
    display: none;
  }

  .banner {
    height: 25vh;
    margin-bottom: 18px;
  }

  .banner > i {
    font-size: 4em !important;
  }

  .error-header,
  .summary-header {
    font-size: 22px;
    margin: 20px 0 20px 0;
  }

  .error-subtext,
  .summary-subtext {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .error-reason,
  .summary-instructions {
    font-size: 14px;
  }

  .error-content,
  .summary-content {
    padding: 0;
  }

  .returnToHomeBtn {
    position: absolute;
    width: calc(100% - 36px) !important;
    bottom: 36px;
    right: 18px;
    margin: 0 !important;
  }
}

@media only screen and (max-width: 330px) {
  .radiusField > .field > div {
    padding: 11.5px !important;
  }
}

@media only screen and (min-width: 1100px) {
  .FormContainer {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    padding: 0 !important;
  }

  .towImage {
    width: 40%;
    margin: 0;
    display: block;
  }

  .formDetails {
    flex-direction: column;
    width: 60%;
    padding: 30px;
  }

  .titleHeader {
    font-size: 28px;
  }

  .imageContainer {
    position: relative;
    top: 20%;
  }

  .banner {
    margin: 0;
  }

  .error-content,
  .summary-content {
    padding: 50px;
  }

  .returnToHomeBtn {
    width: 20% !important;
    margin-left: 50px !important;
  }
}
